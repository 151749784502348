<template>
  <footer>
    <div class="content">
      <div class="time">
        Copyright &copy;2019-{{ new Date().getFullYear() }} 上海马斯夫物联科技有限公司 All
        Rights Reserved.
      </div>
      <div class="num">
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >沪ICP备2023033179号</a
        >
        <div class="policeNum">
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011802004434"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >31011802004434</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="less" scoped>
footer {
  background: #18222c;
  // margin-top: 10px;
  .content {
    width: 1200px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    height: 68px;
    .time {
      font-size: 12px;
      .police {
        width: 24px;
        height: 24px;
        margin: 0 5px;
      }
    }
    .num {
      margin-top: 5px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      .link {
        color: #fff;
        &:nth-of-type(1) {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>